"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var didomiConsent = function (consentList) {
    var didomi = window.Didomi;
    var consentOk = consentList.every(function (consent) {
        return didomi.getUserStatus().purposes.consent.enabled.includes(consent);
    });
    return consentOk;
};
exports.didomiShowPreferences = function () {
    window.Didomi.preferences.show();
};
var useDidomiGetConsent = function (consentList) {
    var _a = react_1.useState(false), isConsent = _a[0], setConsent = _a[1];
    react_1.useEffect(function () {
        window.didomiOnReady = window.didomiOnReady || [];
        window.didomiEventListeners = window.didomiEventListeners || [];
        window.didomiEventListeners.push({
            event: 'consent.changed',
            listener: function () {
                if (didomiConsent(consentList)) {
                    setConsent(true);
                }
            },
        });
        window.didomiOnReady.push(function () {
            if (didomiConsent(consentList)) {
                setConsent(true);
            }
        });
    });
    return isConsent;
};
exports.default = useDidomiGetConsent;
