"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var formatterPhone = function (value) {
    if (value === 0)
        return '';
    var newValue = value.toString().replace(/[^\d]/g, '');
    var reg = /(\d)(?=(\d{2})+(?!\d))/g;
    while (reg.test(newValue))
        newValue = newValue.replace(reg, '$1 ');
    return newValue;
};
exports.default = {
    phone: formatterPhone,
};
