"use strict";
/*! *****************************************************************************
Ce tooltip est un modification de la lib-ui version 2.5.2
sur cette version et les versions précedents il y a un bug de posisition du tooltip
Pour regler ce problème faudra :
1 - Utiliser useEffect à la place de useLayoutEffect car IE et safari
    ont du mal à arreter le loop qui est produit par ce dernier.
2 - Pour l'anchorRect et selfRect Utiliser getAbsoluteBoundingRect à la place de
    getBoundingClientRect car cette fonction prevoit aussi les distances avec scroll
3 - Ne pas utiliser l'event listener 'scroll' car déclenche des renders en plus pour rien
***************************************************************************** */
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var types_1 = require("@sl/ui-library/lib/types");
var CROPPED_OFFSET = 10;
exports.horizontalOrientations = types_1.tuple('left', 'right');
exports.verticalOrientations = types_1.tuple('top', 'bottom');
exports.isHorizontalOrientation = function (orientation) {
    return exports.horizontalOrientations.includes(orientation);
};
exports.isVerticalOrientation = function (orientation) {
    return exports.verticalOrientations.includes(orientation);
};
function getOffset(size, offset, direction) {
    return typeof offset === 'number' ?
        offset * direction :
        offset(size) * direction;
}
function calculatePosition(anchorRect, selfRect, orientation, _a) {
    var _b = _a.mainAxis, mainAxis = _b === void 0 ? 0 : _b, _c = _a.crossAxis, crossAxis = _c === void 0 ? 0 : _c, _d = _a.anchor, anchor = _d === void 0 ? 0 : _d;
    switch (orientation) {
        case 'right':
            return {
                top: anchorRect.top + getOffset(selfRect.height, crossAxis, -1) + getOffset(anchorRect.height, anchor, 1),
                left: anchorRect.left + anchorRect.width + getOffset(selfRect.width, mainAxis, 1)
            };
        case 'left':
            return {
                top: anchorRect.top + getOffset(selfRect.height, crossAxis, -1) + getOffset(anchorRect.height, anchor, 1),
                left: anchorRect.left - selfRect.width + getOffset(selfRect.width, mainAxis, -1)
            };
        case 'top':
            return {
                top: anchorRect.top - selfRect.height + getOffset(selfRect.height, mainAxis, -1),
                left: anchorRect.left + getOffset(selfRect.width, crossAxis, -1) + getOffset(anchorRect.width, anchor, 1)
            };
        case 'bottom':
            return {
                top: anchorRect.top + anchorRect.height + getOffset(selfRect.height, mainAxis, 1),
                left: anchorRect.left + getOffset(selfRect.width, crossAxis, -1) + getOffset(anchorRect.width, anchor, 1)
            };
    }
}
var getAbsoluteBoundingRect = function (el) {
    var doc = document;
    var win = window;
    var body = doc.body;
    var offsetX = win.pageXOffset !== undefined ? win.pageXOffset :
        (doc.documentElement || body.parentNode || body).scrollLeft;
    var offsetY = win.pageYOffset !== undefined ? win.pageYOffset :
        (doc.documentElement || body.parentNode || body).scrollTop;
    var rect = el.getBoundingClientRect();
    if (el !== body) {
        var parent = el.parentNode;
        while (parent !== body && parent !== null) {
            //@ts-ignore
            offsetX += parent.scrollLeft;
            //@ts-ignore
            offsetY += parent.scrollTop;
            parent = parent.parentNode;
        }
    }
    return {
        bottom: rect.bottom + offsetY,
        height: rect.height,
        left: rect.left + offsetX,
        right: rect.right + offsetX,
        top: rect.top + offsetY,
        width: rect.width,
    };
};
function useAnchor(anchorRef, selfRef, options) {
    if (options === void 0) { options = {}; }
    var _a = options.orientation, orientation = _a === void 0 ? 'left' : _a, _b = options.offset, offset = _b === void 0 ? {} : _b, shouldRelayout = options.shouldRelayout, _c = options.reflectMainAxisWhenCropped, reflectMainAxisWhenCropped = _c === void 0 ? false : _c, _d = options.moveCrossAxisWhenCropped, moveCrossAxisWhenCropped = _d === void 0 ? false : _d;
    var lastOrientation = react_1.useRef(orientation);
    var calculatedOrientation = react_1.useRef(orientation);
    var crossAxisDifference = react_1.useRef(0);
    var _e = react_1.useState({ top: 0, left: 0 }), anchor = _e[0], setAnchor = _e[1];
    var lastAnchor = react_1.useRef(anchor);
    if (lastOrientation.current !== orientation) {
        lastOrientation.current = orientation;
        calculatedOrientation.current = orientation;
    }
    var reposition = react_1.useCallback(function () {
        if (!anchorRef.current || !selfRef.current || (shouldRelayout && !shouldRelayout()))
            return;
        var anchorRect = getAbsoluteBoundingRect(anchorRef.current);
        var selfRect = getAbsoluteBoundingRect(selfRef.current);
        var _a = calculatePosition(anchorRect, selfRect, orientation, offset), top = _a.top, left = _a.left;
        var isCroppedVertically = top + selfRect.height > window.innerHeight - CROPPED_OFFSET || top < CROPPED_OFFSET;
        var isCroppedHorizontally = left + selfRect.width > window.innerWidth - CROPPED_OFFSET || left < CROPPED_OFFSET;
        if (reflectMainAxisWhenCropped) {
            calculatedOrientation.current = orientation;
            if (exports.isHorizontalOrientation(orientation) && isCroppedHorizontally) {
                var newOrientation = orientation === 'left' ? 'right' : 'left';
                var _b = calculatePosition(anchorRect, selfRect, newOrientation, offset), recalculedTop = _b.top, recalculedLeft = _b.left;
                if (recalculedLeft + selfRect.width <= window.innerWidth - CROPPED_OFFSET && recalculedLeft >= CROPPED_OFFSET) {
                    calculatedOrientation.current = newOrientation;
                    top = recalculedTop;
                    left = recalculedLeft;
                }
            }
            if (exports.isVerticalOrientation(orientation) && isCroppedVertically) {
                var newOrientation = orientation === 'top' ? 'bottom' : 'top';
                var _c = calculatePosition(anchorRect, selfRect, newOrientation, offset), recalculedTop = _c.top, recalculedLeft = _c.left;
                if (recalculedTop + selfRect.height <= window.innerHeight - CROPPED_OFFSET && recalculedTop >= CROPPED_OFFSET) {
                    calculatedOrientation.current = newOrientation;
                    top = recalculedTop;
                    left = recalculedLeft;
                }
            }
        }
        if (moveCrossAxisWhenCropped) {
            if (exports.isHorizontalOrientation(orientation) && isCroppedVertically) {
                var recalculatedTop = void 0;
                if (top < CROPPED_OFFSET) {
                    recalculatedTop = Math.min(CROPPED_OFFSET, anchorRect.top);
                }
                else if (top + selfRect.height > window.innerHeight - CROPPED_OFFSET) {
                    recalculatedTop = Math.max(window.innerHeight - CROPPED_OFFSET - selfRect.height, anchorRect.top + anchorRect.height - selfRect.height);
                }
                if (recalculatedTop) {
                    crossAxisDifference.current = recalculatedTop - top;
                    top = recalculatedTop;
                }
            }
            if (exports.isVerticalOrientation(orientation) && isCroppedHorizontally) {
                var recalculatedLeft = void 0;
                if (left < CROPPED_OFFSET) {
                    recalculatedLeft = Math.min(CROPPED_OFFSET, anchorRect.left);
                }
                else if (left + selfRect.width > window.innerWidth - CROPPED_OFFSET) {
                    recalculatedLeft = Math.max(window.innerWidth - CROPPED_OFFSET - selfRect.width, anchorRect.left + anchorRect.width - selfRect.width);
                }
                if (recalculatedLeft) {
                    crossAxisDifference.current = recalculatedLeft - left;
                    left = recalculatedLeft;
                }
            }
        }
        if (lastAnchor.current.top === top && lastAnchor.current.left === left) {
            return;
        }
        lastAnchor.current = { top: top, left: left };
        setAnchor({ top: top, left: left });
    }, [
        offset.mainAxis,
        offset.crossAxis,
        offset.anchor,
        orientation,
        reflectMainAxisWhenCropped,
        moveCrossAxisWhenCropped,
        anchorRef,
        selfRef,
        shouldRelayout
    ]);
    react_1.useEffect(function () {
        if (shouldRelayout && !shouldRelayout())
            return;
        window.addEventListener('resize', reposition);
        reposition();
        return function () {
            window.removeEventListener('resize', reposition);
        };
    }, [
        offset.mainAxis,
        offset.crossAxis,
        offset.anchor,
        orientation,
        reflectMainAxisWhenCropped,
        moveCrossAxisWhenCropped,
        anchorRef,
        selfRef,
        shouldRelayout
    ]);
    return {
        anchor: anchor,
        orientation: calculatedOrientation.current,
        crossAxisDifference: crossAxisDifference.current,
    };
}
exports.useAnchor = useAnchor;
