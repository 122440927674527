"use strict";
/*! *****************************************************************************
Ce tooltip est un modification de la lib-ui version 2.5.2
sur cette version et les versions précedents il y a un bug de posisition du tooltip
Pour regler ce problème faudra :
1 - Dans le style TooltipContainer la position doit passer de fixed à absolute
    Pour n'est pas récalculer la position à chaque scroll de la page
2 - Utiliser le hook custom useAnchor
***************************************************************************** */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var types_1 = require("@sl/ui-library/lib/types");
var useClickOutside_1 = require("@sl/ui-library/lib/hooks/useClickOutside");
var useAnchor_1 = require("./useAnchor");
var assertStyle_1 = require("@sl/ui-library/lib/assertStyle");
exports.tooltipCSSProperties = types_1.tuple.apply(void 0, __spreadArrays(assertStyle_1.positioningStyles, assertStyle_1.miscStyles));
var setArrowPosition = function (orientation) {
    switch (orientation) {
        case 'bottom':
            return styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        top: 100%;\n        left: 50%;\n        transform: translateX(-50%);\n\n        &:after {\n          top: 0;\n          left: 50%;\n        }\n      "], ["\n        top: 100%;\n        left: 50%;\n        transform: translateX(-50%);\n\n        &:after {\n          top: 0;\n          left: 50%;\n        }\n      "])));
        case 'top':
            return styled_components_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        top: -20px;\n        left: 50%;\n        transform: translateX(-50%);\n\n        &:after {\n          top: 20px;\n          left: 50%;\n        }\n      "], ["\n        top: -20px;\n        left: 50%;\n        transform: translateX(-50%);\n\n        &:after {\n          top: 20px;\n          left: 50%;\n        }\n      "])));
        case 'right':
            return styled_components_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        top: 50%;\n        left: 100%;\n        transform: translateY(-50%);\n\n        &:after {\n          top: 10px;\n          left: 0%;\n        }\n      "], ["\n        top: 50%;\n        left: 100%;\n        transform: translateY(-50%);\n\n        &:after {\n          top: 10px;\n          left: 0%;\n        }\n      "])));
        case 'left':
            return styled_components_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        top: 50%;\n        left: -20px;\n        transform: translateY(-50%);\n\n        &:after {\n          top: 10px;\n          left: 20px;\n        }\n      "], ["\n        top: 50%;\n        left: -20px;\n        transform: translateY(-50%);\n\n        &:after {\n          top: 10px;\n          left: 20px;\n        }\n      "])));
    }
};
var reflectArrowPosition = function (orientation) {
    switch (orientation) {
        case 'left': return 'right';
        case 'right': return 'left';
        case 'top': return 'bottom';
        case 'bottom': return 'top';
    }
};
var Arrow = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  overflow: hidden;\n  width: 20px;\n  height: 20px;\n\n  &:after {\n    content: '';\n    position: absolute;\n    width: 10px;\n    height: 10px;\n    transform: translateX(-50%) translateY(-50%) rotate(45deg);\n    background-color: ", ";\n    box-shadow: 0 0 12px 0 rgba(38, 38, 38, 0.2);\n  }\n\n  ", "\n"], ["\n  position: absolute;\n  overflow: hidden;\n  width: 20px;\n  height: 20px;\n\n  &:after {\n    content: '';\n    position: absolute;\n    width: 10px;\n    height: 10px;\n    transform: translateX(-50%) translateY(-50%) rotate(45deg);\n    background-color: ", ";\n    box-shadow: 0 0 12px 0 rgba(38, 38, 38, 0.2);\n  }\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.secondaryBackgroundColor;
}, function (_a) {
    var position = _a.orientation;
    return setArrowPosition(position);
});
var TooltipContainer = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  padding: 10px;\n  background-color: ", ";\n  border-radius: 2px;\n  box-shadow: 0 0 12px 0 rgba(38, 38, 38, 0.2);\n  user-select: none;\n"], ["\n  position: absolute;\n  padding: 10px;\n  background-color: ", ";\n  border-radius: 2px;\n  box-shadow: 0 0 12px 0 rgba(38, 38, 38, 0.2);\n  user-select: none;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.secondaryBackgroundColor;
});
var Tooltip = function (_a) {
    var children = _a.children, anchorRef = _a.anchorRef, _b = _a.orientation, orientation = _b === void 0 ? 'left' : _b, _c = _a.reflectMainAxisWhenCropped, reflectMainAxisWhenCropped = _c === void 0 ? false : _c, _d = _a.moveCrossAxisWhenCropped, moveCrossAxisWhenCropped = _d === void 0 ? false : _d, onClick = _a.onClick, onClickOutside = _a.onClickOutside, zIndex = _a.zIndex, _e = _a["data-test"], dataTest = _e === void 0 ? 'sl-ui.tooltip' : _e, props = __rest(_a, ["children", "anchorRef", "orientation", "reflectMainAxisWhenCropped", "moveCrossAxisWhenCropped", "onClick", "onClickOutside", "zIndex", 'data-test']);
    assertStyle_1.assertStyle(props.style, exports.tooltipCSSProperties);
    var tooltipRef = react_1.useRef(null);
    var _f = useAnchor_1.useAnchor(anchorRef, tooltipRef, {
        orientation: orientation,
        offset: {
            anchor: function (anchorSize) { return anchorSize * .5; },
            crossAxis: function (tooltipSize) { return tooltipSize * .5; },
            mainAxis: 20,
        },
        reflectMainAxisWhenCropped: reflectMainAxisWhenCropped,
        moveCrossAxisWhenCropped: moveCrossAxisWhenCropped,
    }), _g = _f.anchor, top = _g.top, left = _g.left, calculatedOrientation = _f.orientation, crossAxisDifference = _f.crossAxisDifference;
    useClickOutside_1.useClickOutside([anchorRef, tooltipRef], onClickOutside);
    var style = {
        top: top + "px",
        left: left + "px",
        zIndex: zIndex,
    };
    var arrowStyle = {};
    if (orientation === 'left' || orientation === 'right') {
        arrowStyle.marginTop = -crossAxisDifference + "px";
    }
    else if (orientation === 'top' || orientation === 'bottom') {
        arrowStyle.marginLeft = -crossAxisDifference + "px";
    }
    return (react_1.default.createElement(TooltipContainer, __assign({ "data-test": dataTest }, { onClick: onClick, ref: tooltipRef, style: style }),
        children,
        react_1.default.createElement(Arrow, { orientation: reflectArrowPosition(calculatedOrientation), style: arrowStyle })));
};
Tooltip.propTypes = __assign({}, types_1.baseUiPropTypes(__assign(__assign({}, assertStyle_1.positioningStylesPropTypes), assertStyle_1.miscStylesPropTypes)));
exports.default = Tooltip;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
