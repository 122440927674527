"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = require("styled-components");
var cdnUrl = 'https://website-assets.seloger.com/';
var fontDirPath = 'commons/fonts/cera-sl/';
var fontName = 'CeraSLsys';
var hash = '';
function slFontFace() {
    return styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    @font-face {\n      font-family: 'Cera SL';\n      font-style: normal;\n      font-weight: 400;\n      src: url('", "", "", "-Regular", ".woff2') format('woff2');\n      font-display: swap;\n    }\n    @font-face {\n      font-family: 'Cera SL';\n      font-style: normal;\n      font-weight: 800;\n      src: url('", "", "", "-Bold", ".woff2') format('woff2');\n      font-display: swap;\n    }\n  "], ["\n    @font-face {\n      font-family: 'Cera SL';\n      font-style: normal;\n      font-weight: 400;\n      src: url('", "", "", "-Regular", ".woff2') format('woff2');\n      font-display: swap;\n    }\n    @font-face {\n      font-family: 'Cera SL';\n      font-style: normal;\n      font-weight: 800;\n      src: url('", "", "", "-Bold", ".woff2') format('woff2');\n      font-display: swap;\n    }\n  "])), cdnUrl, fontDirPath, fontName, hash, cdnUrl, fontDirPath, fontName, hash);
}
exports.slFontFace = slFontFace;
var templateObject_1;
