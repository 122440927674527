"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.disableBodyScroll = function () {
    // Mozilla, chrome IE, opera, etc Desktop browser
    document.body.style.overflow = 'hidden';
    // Safary
    var scrollPosition = window.pageYOffset;
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    // Prevents Mobile Safari from bumping up font sizes in landscape
    document.body.style.webkitTextSizeAdjust = '100%';
    document.body.style.msTextSizeAdjust = '100%';
    // Save position
    document.body.style.top = "-" + scrollPosition + "px";
};
exports.enableBodyScroll = function () {
    // Mozilla, chrome IE, opera
    document.body.style.removeProperty('overflow');
    // Safary
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('width');
    // Prevents Mobile Safari from bumping up font sizes in landscape
    document.body.style.webkitTextSizeAdjust = 'auto';
    document.body.style.msTextSizeAdjust = 'auto';
    // Restore position
    var scrollPosition = document.body.style.top;
    document.body.style.removeProperty('top');
    window.scrollTo(0, parseInt(scrollPosition || '0', 10) * -1);
};
