"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQS = function () {
    if (typeof global.window !== 'undefined')
        return '';
    return window.location.search;
};
exports.qsToObj = function (qs) {
    return qs
        ? JSON.parse("{\"" + decodeURI(qs)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') + "\"}")
        : {};
};
exports.filterQS = function (fullQS, filters) {
    // remove the '?' at the beginning of qs
    var qs = fullQS.substr(1);
    var qsObject = exports.qsToObj(qs);
    var result = filters.find(function (filter) { return qsObject[filter]; });
    return result ? qsObject[result] : '';
};
