"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
  Validation format functions
*/
var validatorEmailFormat = function (email) {
    return email === undefined ? false : email.toLowerCase().match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gim) !== null;
};
var validatorZipCodeFormat = function (zipcode) {
    return /^(?:0[1-9]|[13-8][0-9]|2[ab1-9]|9[0-5])(?:[0-9]{3})?|9[78][1-9](?:[0-9]{2})?$/
        .test(zipcode) && zipcode.length === 5;
};
var validatorPhoneFormat = function (phone) {
    return phone.length === 10 && phone.split('')[0] === "0";
};
var validatorNameFormat = function (name) {
    return name.length > 1;
};
var validatorBirthYearFormat = function (birthdate) { return birthdate.split('')[0] === "1" || "2"; };
var validatorAmountIncomeTaxFormat = function (taxes) { return taxes.length > 3; };
/*
  Validation entry functions
*/
var validatorNameEntry = function (name) {
    return /^[A-ZÂÄÀÉÈÙÊËÎÏÔÖÇÑ '-]*$/i.test(name);
};
var validatorPhoneEntry = function (phone) {
    return /^\+?[0-9]{0,10}$/.test(phone);
};
var validatorZipcodeEntry = function (zipcode) {
    return /^\+?[0-9]{0,5}$/.test(zipcode);
};
var validatorEmailEntry = function (email) {
    return /^\S*$/.test(email);
};
var validatorBirthYearEntry = function (birthdate) { return /^\+?[0-9]{0,4}$/.test(birthdate); };
var validatorAmountIncomeTaxEntry = function (amountIncomeTax) { return /^\+?[0-9]{0,5}$/.test(amountIncomeTax); };
exports.default = {
    format: {
        name: validatorNameFormat,
        email: validatorEmailFormat,
        phone: validatorPhoneFormat,
        zipcode: validatorZipCodeFormat,
        birthYear: validatorBirthYearFormat,
        amountIncomeTax: validatorAmountIncomeTaxFormat
    },
    entry: {
        name: validatorNameEntry,
        email: validatorEmailEntry,
        phone: validatorPhoneEntry,
        zipcode: validatorZipcodeEntry,
        birthYear: validatorBirthYearEntry,
        amountIncomeTax: validatorAmountIncomeTaxEntry
    }
};
