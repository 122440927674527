"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        id: 'MyFunding',
        url: '/credit-immobilier/mon-financement',
        isNew: false,
        label: 'Mon financement',
        medium: 'menu-mon_financement',
    },
    {
        id: 'MyBorrowerInsurance',
        url: '/credit-immobilier/mon-assurance-emprunteur',
        label: 'Mon assurance de prêt',
        medium: 'menu-assurance_emprunteur',
    },
    {
        id: 'Monthly',
        url: '/credit-immobilier/simulateur-mensualites',
        label: 'Mes mensualités',
        medium: 'menu-mes_mensualites',
    },
    {
        id: 'MyTools',
        url: '/credit-immobilier/mes-outils',
        label: 'Mes outils',
        medium: 'menu-mes_outils',
    },
    {
        id: 'Pinel',
        url: '/credit-immobilier/simulation-investissement',
        label: 'Mon Investissement',
        medium: 'menu-simulation_pinel',
    },
];
