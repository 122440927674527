"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var initialFormError = {
    error: false,
};
exports.initialFormError = initialFormError;
var initialFormState = {
    name: {
        value: '',
        state: 'normal',
    },
    mail: {
        value: '',
        state: 'normal',
    },
    phone: {
        value: '',
        state: 'normal',
    },
    zipCode: {
        value: '',
        state: 'normal',
    },
    birthYear: {
        value: undefined,
        state: 'normal',
    },
    amountIncomeTax: {
        value: 0,
        state: 'normal',
    },
    legalAcept: false,
    isSending: false,
};
exports.initialFormState = initialFormState;
var initialPartnersState = {
    contactModalDisplayed: false,
    contactRequested: false,
};
exports.initialPartnersState = initialPartnersState;
