"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
exports.useDebouncedResize = function (callback, delay) {
    react_1.useEffect(function () {
        var timeoutId;
        var handleResize = function () {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(function () {
                callback();
            }, delay);
        };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timeoutId);
        };
    }, [callback, delay]);
};
