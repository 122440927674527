"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    _default: {},
    namespace: "",
    data: {},
    init: function (namespace) {
        if (namespace === void 0) { namespace = 'SeLoger_Finances'; }
        var date = new Date();
        this.namespace = namespace;
        this._default = {
            data: {},
            date: { created: date, updated: date }
        };
        return this;
    },
    create: function () {
        var ls = JSON.stringify(this._default);
        try {
            localStorage.setItem(this.namespace, ls);
        }
        catch (err) {
            console.log("can't save to storage");
        }
        return ls;
    },
    load: function () {
        try {
            var ls = localStorage.getItem(this.namespace) || this.create();
            return JSON.parse(ls);
        }
        catch (err) {
            return this._default;
        }
    },
    save: function (data) {
        var ls = this.load();
        ls.data = __assign(__assign({}, ls.data), data);
        ls.date = __assign(__assign({}, ls.date), { updated: new Date() });
        try {
            localStorage.setItem(this.namespace, JSON.stringify(ls));
        }
        catch (err) { }
    },
    clean: function () {
        try {
            localStorage.removeItem(this.namespace);
        }
        catch (err) { }
    }
}.init();
