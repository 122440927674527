"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = __importStar(require("styled-components"));
var core_1 = require("@ui-library/core");
exports.StyledContainerPinel = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  color: #2b2b2b;\n  background: #ffffff;\n\n  h2 {\n    font-size: 1.5rem;\n    font-weight: 400;\n    padding: 0;\n    margin: 0 0 1.5rem 0;\n\n    b {\n      font-weight: 600;\n    }\n\n    &::after {\n      content: ' ';\n      display: block;\n      width: 24px;\n      height: 4px;\n      border-radius: 4px;\n      background-color: ", ";\n      margin-top: 0.75rem;\n    }\n\n    @media screen and (max-width: 780px) {\n      margin: 0 0 1.5rem 0;\n    }\n  }\n\n  h3 {\n    font-size: 1.5rem;\n    font-weight: 400;\n  }\n\n  h4 {\n    font-size: 1.125rem;\n    font-weight: 600;\n    line-height: 1.5rem;\n    margin: 0;\n  }\n"], ["\n  width: 100%;\n  color: #2b2b2b;\n  background: #ffffff;\n\n  h2 {\n    font-size: 1.5rem;\n    font-weight: 400;\n    padding: 0;\n    margin: 0 0 1.5rem 0;\n\n    b {\n      font-weight: 600;\n    }\n\n    &::after {\n      content: ' ';\n      display: block;\n      width: 24px;\n      height: 4px;\n      border-radius: 4px;\n      background-color: ", ";\n      margin-top: 0.75rem;\n    }\n\n    @media screen and (max-width: 780px) {\n      margin: 0 0 1.5rem 0;\n    }\n  }\n\n  h3 {\n    font-size: 1.5rem;\n    font-weight: 400;\n  }\n\n  h4 {\n    font-size: 1.125rem;\n    font-weight: 600;\n    line-height: 1.5rem;\n    margin: 0;\n  }\n"])), function (props) { return (props.isLogicImmo ? '#008dcf' : '#e00034'); });
exports.StyledHat = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1rem;\n  width: 100%;\n  padding: 0.75rem 0;\n\n  @media screen and (max-width: 780px) {\n    margin-top: 0;\n\n    h3 {\n      margin: 0 0 0.5rem 0;\n    }\n  }\n"], ["\n  font-size: 1rem;\n  width: 100%;\n  padding: 0.75rem 0;\n\n  @media screen and (max-width: 780px) {\n    margin-top: 0;\n\n    h3 {\n      margin: 0 0 0.5rem 0;\n    }\n  }\n"])));
exports.StyledContentPinel = styled_components_1.default.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 1rem;\n  margin-right: 1rem;\n  max-width: 1152px;\n\n  @media screen and (min-width: 1184px) {\n    margin-left: auto;\n    margin-right: auto;\n  }\n"], ["\n  margin-left: 1rem;\n  margin-right: 1rem;\n  max-width: 1152px;\n\n  @media screen and (min-width: 1184px) {\n    margin-left: auto;\n    margin-right: auto;\n  }\n"])));
exports.StyledBrandingButton = styled_components_1.default(core_1.Button)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"
    // SC-3275 : le lead est validé si l'âge est entre 25 et 55 ans et le montant d'impôt sur le revenu/an est supérieur à 2500€"
])), function (props) {
    if (props.isLogicImmo) {
        return styled_components_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        border-radius: 4px;\n        border-color: #ff6f61 !important;\n        background-color: #ff6f61 !important;\n      "], ["\n        border-radius: 4px;\n        border-color: #ff6f61 !important;\n        background-color: #ff6f61 !important;\n      "])));
    }
    return styled_components_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      background: linear-gradient(45deg, #e30513 0%, #fe004b 100%)!important;\n      border: 0;\n\n      span {\n        position: relative;\n        z-index: 1;\n      }\n\n      &::before {\n        content: '';\n        position: absolute;\n        inset: 0;\n        background: #e30513;\n        opacity: 0;\n        pointer-events: none;\n        transition: opacity 0.15s ease 0s;\n        z-index: 0;\n        border-radius: 40px;\n      }\n      \n      &:hover {\n        box-shadow: none!important;\n        &:not(:disabled) {\n          &::before {\n            opacity: 1;\n          }\n        }\n      }\n    "], ["\n      background: linear-gradient(45deg, #e30513 0%, #fe004b 100%)!important;\n      border: 0;\n\n      span {\n        position: relative;\n        z-index: 1;\n      }\n\n      &::before {\n        content: '';\n        position: absolute;\n        inset: 0;\n        background: #e30513;\n        opacity: 0;\n        pointer-events: none;\n        transition: opacity 0.15s ease 0s;\n        z-index: 0;\n        border-radius: 40px;\n      }\n      \n      &:hover {\n        box-shadow: none!important;\n        &:not(:disabled) {\n          &::before {\n            opacity: 1;\n          }\n        }\n      }\n    "])));
});
// SC-3275 : le lead est validé si l'âge est entre 25 et 55 ans et le montant d'impôt sur le revenu/an est supérieur à 2500€"
exports.isValidLead = function (birthYear, amountIncomeTax) {
    var age = new Date().getFullYear() - (birthYear || new Date().getFullYear());
    return age >= 25 && age <= 55 && (amountIncomeTax || 0) > 1;
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
