"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSourceParam = function () {
    try {
        return new URLSearchParams(window.location.search).get('m');
    }
    catch (ErrorMessage) {
        return null;
    }
};
