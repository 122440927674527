"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Tracking = /** @class */ (function () {
    function Tracking() {
    }
    Tracking.PushEvent = function (eventName, vars) {
        var savedThor = global.thor ? global.thor : null;
        var doIt = function () {
            if (savedThor !== null) {
                savedThor.pushEvent(eventName, vars);
            }
        };
        var fastRequest = eventName === 'view';
        if (fastRequest) {
            // for requests tested to be fast, send them immediatelly
            doIt();
        }
        else if ('requestIdleCallback' in global) {
            // in modern browsers, wait until the browser is not occupied
            // by more important stuff, like animations or user input
            // but send the push no later than 250ms
            // (actually it will be sent later, but it's good enough)
            global.requestIdleCallback(doIt, { timeout: 250 });
        }
        else {
            // in older browsers just delay the execution of tracking
            setTimeout(doIt);
        }
    };
    Tracking.PushView = function (state) {
        this.PushEvent('view', __assign({ site: 'SL', language: 'fr', url_path: window.location.href, environment: global.environment, referrer: window.document.referrer }, state));
    };
    Tracking.PushPageView = function (state) {
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        var isLoggedIn = window && window.authModule && window.authModule.authentication && window.authModule.authentication.isConnected() ? 'logged' : 'unlogged';
        this.PushEvent('page_view', {
            av_pagetitle: state.pageTitle,
            av_url_path: window.location.pathname,
            av_category: 'finance',
            av_environment: global.environment,
            av_language: 'fr',
            av_platform: 'web',
            av_site: state.avSite || 'SL',
            av_user: {
                // @ts-ignore
                // tslint:disable-next-line:max-line-length
                uid: window && window.authModule && window.authModule.localStorageSafe ? window.authModule.localStorageSafe.getItem('user_id') : undefined,
                // @ts-ignore
                // tslint:disable-next-line:max-line-length
                status: window && window.authModule && window.authModule.authentication ? isLoggedIn : 'unlogged',
                project: 'invest',
            },
        });
    };
    Tracking.PushInteraction = function (state) {
        if (state.int_type !== 'none') {
            this.PushEvent('interaction', state);
        }
    };
    Tracking.ApplyTracking = function (url, medium) {
        return url + "?m=" + medium;
    };
    Tracking.PushLeadCreditinsurFormOpened = function (state) {
        this.PushEvent('lead_creditinsur_form_opened', state);
    };
    Tracking.PushLeadCreditinsurFormSubmit = function (state) {
        this.PushEvent('lead_creditinsur_form_submit', state);
    };
    return Tracking;
}());
exports.default = Tracking;
